import { ref } from "vue";
import {
  projectFunctions,
  projectFirestore,
  projectAuth,
} from "../firebase/config";

import EnvUtils from "../misc/env";

const error = ref(null);

let rewardfulApi = projectFunctions.httpsCallable(
  "getResponseFromRewardfulApi"
);

const createRewardfulUserData = async () => {
  const userId = projectAuth.currentUser.uid;

  const user = await projectFirestore.collection("users").doc(userId).get();

  if (user.exists) {
    // Create the data object
    // Get the user's full name
    const fullName = user.data().fullName;
    // Split the full name by space
    const nameParts = fullName.trim().split(" ");
    // Get the first name
    const first = nameParts[0];
    // Get the last name, if there is one
    const last =
      nameParts.length > 1 ? nameParts[nameParts.length - 1] : "null";
    // Use the last name or an empty string as needed

    const email = user.data().email;

    const data = {
      firstName: first,
      lastName: last,
      email: email,
    };

    return data;
  } else {
    error.value = "User does not exist";

    return null;
  }
};

const rewardful = () => {
  const getAffiliate = async () => {
    const userId = projectAuth.currentUser.uid;

    const user = await projectFirestore.collection("users").doc(userId).get();

    if (user?.data().affiliateId) {
      const data = {
        url: `/affiliates/${user.data().affiliateId}`,
        method: "GET",
      };

      try {
        const rewardfulResponse = await rewardfulApi(data);
        console.log("rewardfulResponse: ", rewardfulResponse);

        const response = {
          affiliateId: user.data().affiliateId,
          response: rewardfulResponse,
        };

        return response;
      } catch (e) {
        console.error("Error getting rewardful affiliate: ", e);
        error.value = "Something went wrong, please refresh and try again";
      }
    } else {
      const response = {
        affiliateId: null,
        response: null,
      };

      return response;
    }
  };

  const createAffiliate = async () => {
    const user = await createRewardfulUserData();

    const campaignId = EnvUtils.REWARDFUL_REWARDS_CAMPAIGN_ID;

    if (user) {
      const data = {
        url: "/affiliates",
        method: "POST",
        body: {
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          wise_email: user.email,
          campaign_id: campaignId,
        },
      };

      try {
        const rewardfulResponse = await rewardfulApi(data);

        if (rewardfulResponse) {
          const response = {
            affiliateId: rewardfulResponse.data.id,
            response: rewardfulResponse,
          };

          // update user doc
          await projectFirestore
            .collection("users")
            .doc(projectAuth.currentUser.uid)
            .update({
              affiliateId: response.affiliateId,
            });

          return response;
        } else {
          throw new Error("Failed to get rewardful API response");
        }
      } catch (err) {
        console.error("Error creating affiliate: ", err);
        error.value = "Something went wrong, please refresh and try again";
        return null;
      }
    } else {
      return null;
    }
  };

  return { getAffiliate, createAffiliate, error };
};

export default rewardful;
