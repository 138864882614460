<template>
	<Alert :message="error" v-if="error" />
	<Sidebar />
	<div class="right-content">
		<div class="container">
			<div class="row">
				<div class="banner">
					<div class="split">
						<div class="headline">
							<h1>Earn <span class="accent">£60</span></h1>
							<p>Thank you for supporting Risk Memo</p>
						</div>
						<div class="info">
							<ol type="1">
								<li>Share your referral link</li>
								<li>Get 3 (or more) conversions</li>
								<li>Receive rewards straight to your bank</li>
							</ol>
						</div>
					</div>
				</div>
			</div>

			<!-- Loading -->
			<div class="row below-banner" v-if="isLoading">
				<PageLoading />
			</div>

			<div
				class="row tile below-banner"
				v-if="!isLoading && affiliate && affiliate.response == null"
			>
				<div class="join">
					<h2>Join our referral program</h2>
					<br />
					<p>
						By clicking "Start earning!" below you agree to our
						referral programme
						<a href="#" target="_blank">terms and conditions</a>
					</p>
					<br />
					<br />
					<button class="btn btn-success" @click="createNewAffiliate">
						Start earning!
					</button>
				</div>
			</div>

			<!-- Loading complete -->
			<div
				class="row tile below-banner"
				v-if="!isLoading && affiliate && affiliate.response != null"
			>
				<div class="row info-list table-row-container">
					<div class="link-container">
						<div class="split">
							<input
								class="link"
								type="text"
								:value="affiliate.response.data.links[0].url"
								disabled
							/>
							<div>
								<button
									v-if="showCopyBtn"
									class="btn btn-success"
									@click="
										copyLink(
											affiliate.response.data.links[0].url
										)
									"
								>
									Copy referral link
								</button>
								<div v-else class="copy-msg">Copied!</div>
							</div>
						</div>
					</div>
				</div>
				<div class="split">
					<div class="row info-list table-row-container">
						<div class="collection-title">
							<h3>Referral activity:</h3>
						</div>
						<div class="table-row">
							<div class="row-left">
								<div class="title no-img-title">Visitors</div>
							</div>
							<div class="row-right">
								<div class="actions">
									<div class="circle-number">
										{{ affiliate.response.data.visitors }}
									</div>
								</div>
							</div>
						</div>
						<div class="table-row">
							<div class="row-left">
								<div class="title no-img-title">Leads</div>
							</div>
							<div class="row-right">
								<div class="actions">
									<div class="circle-number">
										{{ affiliate.response.data.leads }}
									</div>
								</div>
							</div>
						</div>
						<div class="table-row">
							<div class="row-left">
								<div class="title no-img-title">
									Conversions
								</div>
							</div>
							<div class="row-right">
								<div class="actions">
									<div class="circle-number">
										{{
											affiliate.response.data.conversions
										}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row info-list table-row-container">
						<div class="collection-title">
							<h3>Payouts:</h3>
						</div>
						<div class="table-row">
							<div class="row-left">
								<div class="title no-img-title">Due</div>
							</div>
							<div class="row-right">
								<div class="actions">
									<div class="circle-number">
										{{
											formattedAmount(
												affiliate.response.data
													.commission_stats.currencies
													.GBP.due.currency_iso,
												affiliate.response.data
													.commission_stats.currencies
													.GBP.due.cents
											)
										}}
									</div>
								</div>
							</div>
						</div>
						<div class="table-row">
							<div class="row-left">
								<div class="title no-img-title">Paid</div>
							</div>
							<div class="row-right">
								<div class="actions">
									<div class="circle-number">
										{{
											formattedAmount(
												affiliate.response.data
													.commission_stats.currencies
													.GBP.due.currency_iso,
												affiliate.response.data
													.commission_stats.currencies
													.GBP.paid.cents
											)
										}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useMeta } from "vue-meta";
import Sidebar from "../../components/Sidebar.vue";
import { onMounted, ref } from "vue";
import PageLoading from "../../components/PageLoading.vue";
import rewardful from "../../composables/useRewardful";
import Alert from "../../components/Alert.vue";

export default {
	name: "Rewardful",
	components: { Sidebar, PageLoading, Alert },
	setup() {
		useMeta({ title: "Referrals" });
		const { getAffiliate, createAffiliate, error } = rewardful();
		const affiliate = ref(null);
		const isLoading = ref(false);
		const showCopyBtn = ref(true);

		onMounted(async () => {
			error.value = null;
			isLoading.value = true;
			// returns affiliateId & response
			affiliate.value = await getAffiliate();
			isLoading.value = false;
		});

		const createNewAffiliate = async () => {
			error.value = null;
			isLoading.value = true;
			affiliate.value = await createAffiliate();
			isLoading.value = false;
		};

		const formattedAmount = (currency, ammount) => {
			const formatter = new Intl.NumberFormat("en-GB", {
				style: "currency",
				currency: currency,
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			});
			return formatter.format(ammount / 100); // Convert cents to pounds
		};

		const copyLink = (url) => {
			showCopyBtn.value = false;
			navigator.clipboard.writeText(url);
			setTimeout(() => {
				showCopyBtn.value = true;
			}, 1000);
		};

		return {
			affiliate,
			formattedAmount,
			getAffiliate,
			createNewAffiliate,
			isLoading,
			copyLink,
			showCopyBtn,
			error,
		};
	},
};
</script>
<style lang="scss" scoped>
.container {
	padding: 1em;
}
.banner {
	margin-top: 2em;
	.headline {
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: center;
		padding: 0 4em;
		h1 {
			// margin-top: 2em;
			font-size: 6em;
			font-weight: bold;
		}
		p {
			font-size: 1.2em;
		}
	}

	.info {
		padding-top: 2em;
		width: 50%;
		ol {
			li {
				font-size: 1.2em;
				font-weight: bold;
				margin-bottom: 1.5em;
			}
		}
	}
}

.below-banner {
	margin-top: 3em;
	.join {
		width: 100%;
		text-align: center;
		// padding: 2em 20vw;
	}
}

.copy-msg {
	margin-top: 5px;
	font-weight: bold;
	color: $rm-success;
}

.link-container {
	display: flex;
	align-items: center;
	justify-content: center;

	.link {
		min-width: 85vw;
		font-family: "Poppins", sans-serif;
		font-size: 1em;
		color: $rm-dark-blue;
		padding: 0.3em 1em;
		border-radius: $border-radius;
		border: 1px solid $rm-dark-blue;

		@include respond(medium) {
			min-width: 25vw;
		}
	}
}

.info-list {
	padding: 1em;
}

.circle-number {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 35px;
	height: 35px;
	border-radius: 35px;
	border: 2px solid $rm-blue;
	background-color: $rm-light-grey;
	color: $rm-blue;
	font-size: 1em;
	font-weight: bold;
	padding: 5px;
}
</style>
